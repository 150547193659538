if (module.hot) {
  module.hot.accept();
}

import 'jquery-mask-plugin';
import 'webpack-jquery-ui/css';
import 'webpack-jquery-ui/selectmenu';
import 'slick-carousel';
import sliderFn from "./sliderFn";
import {isElementExist, animateCSS} from "./helpers";
import {
	validateEmail,
	validateName,
	validatePhone,
	isRecallFormFn,
	validateSimpleForm
} from "./validateHelper";
import {AppHttp} from "./appHttp";
// import { settings } from 'cluster';


// *****************************************************************
// Define http
// *****************************************************************
const http = new AppHttp();
// *****************************************************************


// *****************************************************************
// WOW.js - workaround fix
// *****************************************************************
const WOW = require('wowjs');
window.wow = new WOW.WOW();
window.wow.init();
// *****************************************************************


// *****************************************************************
// Document ready
// *****************************************************************
$(document).ready(function () {
	
	// *****************************************************************
	// Catalog tab navigation
	// *****************************************************************
	if (isElementExist('.catalog-header__wrap')) {

		function inactiveCatalog () {
			let sideLeft = document.querySelector('.catalog-header__side_left'),
				sideRight = document.querySelector('.catalog-header__side_right');
				console.log(sideLeft,sideRight);
				if(sideLeft.classList.contains('active')) {
					sideLeft.classList.remove('inactive');
					sideRight.classList.add('inactive');
				} else if (sideRight.classList.contains('active')){
					sideRight.classList.remove('inactive');
					sideLeft.classList.add('inactive');
				}
		}
		
		const catalogSideTabs = document.querySelectorAll('.catalog-header__side');
		const catalogContentPanels = document.querySelectorAll('.catalog-content-wrap');
		
		for (let i = 0; i < catalogSideTabs.length; i++) {
			setCatalogTabHandler(catalogSideTabs[i], i)
		}

		function setCatalogTabHandler(tab, index) {
			tab.addEventListener('click', function (e) {
				if (e.target.classList.contains('catalog-header__side') && !e.target.classList.contains('active')) {
					const tabElement = e.target;
					const curPanel = catalogContentPanels[index + 1];
					
					const startCurPanelAnimation = () => {
						const productsContainer = curPanel.querySelector('.product-cards-row');
						if (tabElement.getAttribute('data-products-panel') === 'cosmetics__block') {
							animateCSS('.catalog-filter-wrap', 'fadeInLeft');
						}
						animateCSS(productsContainer, 'fadeInRight');
					};
					
					// Active navigation
					for (let i = 0; i < catalogSideTabs.length; i++) {
						if (catalogSideTabs[i].classList.contains('active')) {
							catalogSideTabs[i].classList.remove('active');
						}
					}
					tabElement.classList.add('active');
					
					// Active panels
					for (let i = 0; i < catalogContentPanels.length; i++) {
						if (catalogContentPanels[i].classList.contains('active')) {
							const productsContainer = catalogContentPanels[i].querySelector('.product-cards-row');
							// productsContainer.classList.add('animated', 'fadeOutRight');
							const handleAnimationEnd = () => {
								// productsContainer.classList.remove('animated', 'fadeOutRight')
								// productsContainer.removeEventListener('animationend', handleAnimationEnd)
								catalogContentPanels[i].classList.remove('active');
								curPanel.classList.add('active');
								startCurPanelAnimation()
							};
							if (tabElement.getAttribute('data-products-panel') !== 'cosmetics__block') {
								if (catalogContentPanels[1].classList.contains('active')) {
									animateCSS('.catalog-filter-wrap', 'fadeOutLeft');
								}
							}
							animateCSS(productsContainer, 'fadeOutRight', handleAnimationEnd);
						}
					}
					
				}
				e.preventDefault();
				inactiveCatalog();
			})
		}

		function getParameterByName(name, url) {
			if (!url) url = window.location.href;
			name = name.replace(/[\[\]]/g, '\\$&');
			let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
			 results = regex.exec(url);
			if (!results) return null;
			if (!results[2]) return '';
			return decodeURIComponent(results[2].replace(/\+/g, ' '));
		   }
		   
		   let curTab;
		   const initialTab = getParameterByName('type');
		   if(initialTab) {
			if(initialTab === 'cosmetic') {
			 curTab = document.querySelector('[data-products-panel=cosmetics__block]');
			}
			if(initialTab === 'superfood') {
			 curTab = document.querySelector('[data-products-panel=superfoods__block]');
			}
			curTab.click();
		   }
	}
	// *****************************************************************
	

	// *****************************************************************
	// Simple forms
	// *****************************************************************
	// Collect all simple forms on page
	const shortForms = document.querySelectorAll('.form');
	
	// Add event submit + some validate func
	shortForms.forEach(form => {
		if (!form.classList.contains('js-form-to-validate')) return;
		form.addEventListener('submit', submitSimpleForm)
	});
	
	// Fn() for sending data from simple form
	function submitSimpleForm(e) {
		e.preventDefault();
		
		const form = e.target;
		const modalType = form.getAttribute('data-modal-type');
		const isRecallForm = isRecallFormFn(form);
		
		if (validateSimpleForm(form)) {
			// Set url
			let url = form.getAttribute('action') ? form.getAttribute('action') : 'https://jsonplaceholder.typicode.com/posts';
			
			// Define default fields
			let name;
			let phone;
			let email;
			
			name = form.querySelector('[name="name"]');
			phone = form.querySelector('[name="phone"]');
			if (!isRecallForm) {
				email = form.querySelector('[name="email"]');
			}
			
			// Collect form values in one form data Object
			let formData = {
				name: name.value,
				phone: phone.value
			};
			
			if (!isRecallForm) formData.email = email.value;
			
			// Test API to check callback modal window
			http.post(url, formData)
			.then(response => {
				// TODO: Call "thank you modal"
				openModalThanks(modalType);
				name.value = '';
				phone.value = '';
				email.value = '';
				console.log(response)
				
			})
			.catch(error => {
				console.log(error)
			})
		}
	}
	
	// *****************************************************************
	
	
	// *****************************************************************
	// Cart functions (checkout page)
	// *****************************************************************
	if (page.classList.contains('page-basket-payments-delivery')) {

		let i = document.createElement('span');
		i.innerHTML = (' ₽');
		
		const orderOptions = {
			products: [],
			productsSumPrice: 0,
			deliveryPrice: 0,
			discount: 0,
			totalToPay: 0
		};
		
		// count total order price to pay
		function countTotalPriceToPay() {
			let total = orderOptions.productsSumPrice + orderOptions.deliveryPrice - orderOptions.discount
			return total;
		}
		
		// products set price
		function setTotalCartPrice() {
			const productsSumPrice = document.querySelector('.shopping-basket-section__total-price');
			const orderSumPrice = document.querySelector('.form-payments-delivery__order-title_sum');
			const deliveryPrice = document.querySelector('.form-payments-delivery__total-text_delivery-price');
			const totalOrderPrice = document.getElementById('totalOrderPrice');
			const products = document.querySelectorAll('.shopping-basket-section__item');
			
			
			let total = 0;
			
			if (products.length > 0) {
				for (let product of products) {
					const quantity = product.querySelector('.js-counter__input-quantity').value;
					const price = product.querySelector('.js-counter__input-price').value;
					const totalPriceElement = product.querySelector('.js-counter__total-price');
					let totalSingleProductPrice = (+quantity) * (+price);
					totalPriceElement.innerText = `${totalSingleProductPrice}`;
					total += +totalSingleProductPrice;
				}
			}
			orderOptions.productsSumPrice = total;
			productsSumPrice.innerText = `${orderOptions.productsSumPrice}`;
			productsSumPrice.appendChild(i);
			orderSumPrice.innerText = `${orderOptions.productsSumPrice} ₽`;
			deliveryPrice.innerText = `${orderOptions.deliveryPrice} ₽`;
			totalOrderPrice.innerText = `${countTotalPriceToPay()} ₽`;
			

		}
		
		// products quantity functions
		const productsList = document.querySelector('.shopping-basket-section__list');
		productsList.addEventListener('click', changeProductQuantity);
		
		function changeProductQuantity(e) {
			const counterWrapper = e.target.parentElement.parentElement.parentElement;
			const inputQuantity = counterWrapper.querySelector('.js-counter__input-quantity');
			if (e.target.parentElement.classList.contains('js-counter__plus')) {
				inputQuantity.value++;
				setTotalCartPrice();
			}
			if (e.target.parentElement.classList.contains('js-counter__minus')) {
				if (inputQuantity.value > 1) {
					inputQuantity.value--;
					setTotalCartPrice();
				}
			}
			e.preventDefault();
		}
		
		// Remove product from cart
		productsList.addEventListener('click', removeProductFromCart);
		
		function removeProductFromCart(e) {
			if (e.target.parentElement.classList.contains('shopping-basket-section__close')) {
				const productItem = e.target.parentElement.parentElement.parentElement;
				productItem.classList.add('animated', 'fadeOutLeft')
				const handleAnimationEnd = () => {
					productItem.classList.remove('animated', 'fadeOutLeft')
					productItem.removeEventListener('animationend', handleAnimationEnd)
					productItem.remove();
					setTotalCartPrice();
				}
				productItem.addEventListener('animationend', handleAnimationEnd)
			}
			e.preventDefault();
		}
		
		// Show / hide map address ( Delivery checkboxes )
		const deliveryCheckboxesMainWrapper = document.querySelector('[data-delivery-type]');
		deliveryCheckboxesMainWrapper.addEventListener('input', changeDeliveryType);
		
		function changeDeliveryType(e) {
			if (e.target.classList.contains('form-payments-delivery__checkbox')) {
				let checkBox = e.target;
				let dataAddressType = checkBox.getAttribute('data-address');
				let dataPrice = checkBox.getAttribute('data-price');
				let dataAddressForms = document.querySelectorAll('[data-address-checked]');
				let orderDelivery = document.querySelector('.form-payments-delivery__order-text_delivery');
				let orderDeliveryPrice = document.querySelector('.form-payments-delivery__total-text_delivery-price');
				orderDelivery.innerHTML = `${checkBox.nextElementSibling.querySelector('.form-payments-delivery__label-title').innerText}`;
				orderDeliveryPrice.innerHTML = `${dataPrice} ₽`;
				orderOptions.deliveryPrice = +dataPrice;
				dataAddressForms.forEach(item => {
					item.getAttribute('data-address-checked') !== dataAddressType ? item.classList.remove('active') : item.classList.add('active')
				});
				setTotalCartPrice();
			}
			e.preventDefault();
		}
		
		// Payment type checkboxes
		const paymentCheckboxesMainWrapper = document.querySelector('[data-payment-type]');
		paymentCheckboxesMainWrapper.addEventListener('input', changePaymentType);
		
		function changePaymentType(e) {
			if (e.target.classList.contains('form-payments-delivery__checkbox')) {
				let checkBox = e.target;
				let orderPaymentType = document.querySelector('.form-payments-delivery__order-text_payment');
				orderPaymentType.innerHTML = `${checkBox.nextElementSibling.querySelector('.form-payments-delivery__label-title').innerText}`;
			}
			e.preventDefault();
		}
		
		// Form tabs
		const tabs = document.querySelectorAll('.form-payments-delivery__navigation .form-payments-delivery__item');
		const panels = document.querySelectorAll('.form-payments-delivery__box .form-payments-delivery__region');
		
		tabs.forEach((tab, index) => {
			setTabHandler(tab, index);
		});
		
		panels[0].querySelector('input[type=radio]:first-child').dispatchEvent(new Event('input', {
			bubbles: true,
			composed: true
		}));
		
		function setTabHandler(tab, tabPos) {
			tab.addEventListener('click', function () {
				for (let i = 0; i < tabs.length; i++) {
					tabs[i].classList.remove('active');
				}
				tab.classList.add('active');
				
				for (let i = 0; i < panels.length; i++) {
					panels[i].classList.remove('active-panel');
				}
				panels[tabPos].classList.add('active-panel');
				let firstRadioInput = panels[tabPos].querySelector('input[type=radio]:first-child');
				firstRadioInput.checked = true;
				firstRadioInput.dispatchEvent(new Event('input', {bubbles: true, composed: true}));
			});
		}
		
		// Order form submit
		const orderForm = document.getElementById('orderForm');
		orderForm.addEventListener('submit', submitOrderForm);
		
		function submitOrderForm(e) {
			e.preventDefault();
			if (validateFormFields()) {
				
				let name = document.querySelector('#form-payments-delivery__name').value;
				let phone = document.querySelector('#form-payments-delivery__phone').value;
				let email = document.querySelector('#form-payments-delivery__email').value;
				let notes = document.querySelector('#form-payments-delivery__comment').value;
				let promoCode = document.querySelector('#form-payments-delivery__promocode').value;
				let deliveryMailing = document.querySelector('#form-payments-payments-delivery-mailing').value;
				
				let formData = Object.assign(
					{
						name: name,
						phone: phone,
						email: email,
						notes: notes ? notes : '',
						promoCode: promoCode ? promoCode : '',
						deliveryMailing: deliveryMailing
					},
					orderOptions);
				
				console.log('send formData object: ', formData);

				

				
				http.post('https://jsonplaceholder.typicode.com/posts', formData)
				.then(response => {
					console.log(response)
					openModalSale();
				})
				.catch(error => {
					console.log(error)
				})
			}
			
		}
		
		function errorsUI(selector, isValid) {
			let field = selector;
			let display = isValid ? 'none' : 'block';
			let toggleMethod = isValid ? 'remove' : 'add';
			let errorBlock = field.parentElement.querySelector('.form-payments-delivery__form-error');
			field.classList[toggleMethod]('error-field');
			errorBlock ? errorBlock.style.display = display : false
		}
		
		function validateFormFields() {
			// Name
			let name = document.querySelector('#form-payments-delivery__name');
			let nameValue = name.value;
			let nameIsValid = nameValue && nameValue.length > 3 ? validateName(nameValue) : false;
			errorsUI(name, nameIsValid);
			
			// Phone
			let phone = document.getElementById('form-payments-delivery__phone');
			let phoneValue = phone.value;
			let phoneIsValid = phoneValue.length > 3 ? validatePhone(phoneValue) : false;
			errorsUI(phone, phoneIsValid);
			
			// Email
			let email = document.getElementById('form-payments-delivery__email');
			let emailValue = email.value;
			let emailIsValid = emailValue.length > 3 ? validateEmail(emailValue) : false;
			errorsUI(email, emailIsValid);
			
			// Accept with offer
			let conditionCheckbox = document.getElementById('payments-delivery-consent');
			let acceptConditionCheckboxIsValid = conditionCheckbox.checked;
			errorsUI(conditionCheckbox, acceptConditionCheckboxIsValid);
			
			return nameIsValid
				&& phoneIsValid
				&& emailIsValid
				&& acceptConditionCheckboxIsValid;
		}
		
		// Init
		setTotalCartPrice();
	}
	// *****************************************************************
	
	
	// *****************************************************************
	// Youtube Video player (checkout page)
	// *****************************************************************
	let tag = document.createElement('script');
	tag.src = "https://www.youtube.com/player_api";
	let firstScriptTag = document.getElementsByTagName('script')[0];
	firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
	window.onYouTubeIframeAPIReady = function onYouTubeIframeAPIReady() {
		// YouTube client side script has been loaded
		
		let player = new YT.Player('player', {
			height: '360',
			width: '540',
			videoId: 'Nf4F1-4giGw',
			playerVars: {rel: 0, showinfo: 0, ecver: 2},
			origin: 'http://localhost:9000',
			events: {
				'onReady': onPlayerReady,
				'onStateChange': onPlayerStateChange
			}
		});
		
		let buttonPlay = document.querySelector('.video__button'),
			videoBackground = document.querySelector('.video'),
			modalVideo = document.querySelector('.modal-video'),
			modalCloseButton = document.querySelector('.modal-video__close'),
			modalBackground = document.querySelector('.video-wrapper');
		
		function closeModalVideo() {
			modalVideo.classList.remove('show')
			modalVideo.classList.add('hide');
			buttonPlay.classList.remove('hide');
			body.classList.remove('overflow');
		}
		
		function onPlayerReady(event) {
			// playVideo();
			buttonPlay.addEventListener('click', () => {
				buttonPlay.classList.add('hide');
				modalVideo.classList.remove('hide');
				modalVideo.classList.add('show');
				body.classList.add('overflow');
				player.playVideo();
				
			});
		}
		
		var done = false;
		
		function onPlayerStateChange(event) {
			if (isElementExist('.modal-video__close')) {
				modalCloseButton.addEventListener('click', closeModalVideo);
			}
			if (isElementExist('.video-wrapper')) {
				modalBackground.addEventListener('click', closeModalVideo);
			}
		}
		
		if (isElementExist('.modal-video__close')) {
			modalCloseButton.addEventListener('click', stopVideo);
		}
		if (isElementExist('.video-wrapper')) {
			modalBackground.addEventListener('click', stopVideo);
		}
		
		function stopVideo() {
			player.stopVideo();
		}
	}
	// *****************************************************************
	
	
	// *****************************************************************
	// Mini cart popup
	// *****************************************************************
	const basketIcon = document.querySelector('.basket__btn');
	const miniCart = document.querySelector('.mini-cart');
	const miniCartEmpty = document.querySelector('.mini-cart-empty');
	
	basketIcon.addEventListener('mouseenter', showMiniCart);
	
	const isCartEmpty = function () {
		// TODO: check if cart is empty
		return false
	};
	
	function showMiniCart(e) {
		if (e.target.classList.contains('basket__btn')) {
			if (!isCartEmpty()) {
				if (!basketIcon.classList.contains('active')) {
					const timeOut = () => {
						return setTimeout(() => {
							animateCSS(miniCart, 'fadeOut', () => {
								miniCart.classList.remove('active');
								basketIcon.classList.remove('active');
							});
						}, 2000)
					};
					
					miniCart.classList.add('active');
					animateCSS(miniCart, 'fadeIn', () => {
						basketIcon.classList.add('active');
					});
					
					let eventTimeOut;
					
					basketIcon.addEventListener('mouseenter', event => {
						clearTimeout(eventTimeOut);
					});
					
					basketIcon.addEventListener('mouseleave', event => {
						eventTimeOut = timeOut();
					});
					
					miniCart.addEventListener('mouseenter', event => {
						clearTimeout(eventTimeOut);
					});
					
					miniCart.addEventListener('mouseleave', event => {
						eventTimeOut = timeOut();
					})
				}
			}
		}
	}
	
	// *****************************************************************
	
	
	// *****************************************************************
	// Product card
	// *****************************************************************
	
	// product card action block scroll animation
	if(isElementExist('.js-product-card-action-block') && window.innerWidth >= 1920) {
		
		$(window).on('scroll', function () {
			const actionBlock = $('.js-product-card-action-block');
			const anchorBlock = $('.product-card-description__list');
			const bg = document.querySelector('.js-product-card-bg');
			let windowScroll = window.scrollY;
			let startPoint = $('.product-card-section-slider-nav ').offset().top;
			let endPoint = anchorBlock.offset().top - anchorBlock.height();
			
			if(windowScroll >= startPoint) {
				actionBlock.css({transform : `translateY(${windowScroll - startPoint}px)`})
			}
			if(windowScroll >= endPoint) {
				
				// TODO: Animation
				// if(!bg.classList.contains('faded')) {
				// 	animateCSS(bg, 'slideOutRight', function () {
				// 		bg.classList.add('faded');
				// 	})
				// }
				actionBlock.css({transform : `translateY(${endPoint - startPoint}px)`})
			}
		})
	}
	
	// *****************************************************************
	
});


let page = document.querySelector('.page'),
	bannerSection = document.querySelector('.banner'),
	body = document.body;

// if (!!$('.product-card-slider__main') && $('.product-card-slider__main').length > 0 && !!$('.product-card-slider__nav') && $('.product-card-slider__nav').length > 0) {
//   $('.product-card-slider__main').slick({
//    slidesToShow: 1,
//    slidesToScroll: 1,
//    arrows: true,
//    fade: true,
//    asNavFor: '.product-card-slider__nav'
//   });
//   $('.product-card-slider__nav').slick({
//    slidesToShow: 4,
//    slidesToScroll: 1,
//    asNavFor: '.product-card-slider__main',
//    focusOnSelect: true,
//    vertical: true,
//    verticalSwiping: true,
//    responsive: [
//     {
//      breakpoint: 768,
//      settings: {
//       slidesToShow: 3,
//       vertical: false,
//       verticalSwiping: false,
//      }
//     }
//    ]
//   });
//  }






// let out = document.querySelector('.out');

// let k = 0;
// let j = '';
// let n = '';

// function count() {
//   for( let i = 10; i >=0; i-- ) {
//     j += i + ' ';
 
//     n += k;
//     k++;

//   }
//   out.innerHTML = j;
// }
// count();


// $('.banner').on('afterChange', function(event, slick, currentSlide, nextSlide){

// });







// -------------------- ФУНКЦИЯ, ЧТО ДОЛЖНА СРАБАТЫВАТЬ В СЛАЙДЕРЕ

// function animateBannerSlide(){
// let slide = document.querySelector('.banner__slide'),
//     load = document.querySelector('.banner__load-line');
//     slide.classList.add('animate');
//     load.style.display = 'none';
// // function loadLine() {
// //   load.style.display = 'block';
// // }
// // setTimeout(loadLine, 2000);

// }


// animateBannerSlide();



// let arrowRight = document.querySelectorAll('.slick-next');

// arrowRight.forEach(item => {
//   item.addEventListener('click', animateBannerSlide);
// });














// --------------------   momile-menu

let hamburgerMenu = document.querySelector('.hamburger-menu'),
  mobileMenu = document.querySelector('.nav-mobile'),
  entryMob = document.querySelector('.button-entry_mob');

hamburgerMenu.addEventListener('click', ()=> {
  mobileMenu.classList.toggle('active');
  hamburgerMenu.classList.toggle('active');
  body.classList.toggle('overflow');
  entryMob.classList.toggle('active');
})

// --------------------   drop-down-menu

let dropDownLink = document.querySelector('.nav-mobile__link_catalog'),
  dropDownMenu = document.querySelector('.sub-menu'),
  dropDownArrow = document.querySelector('.nav-mobile__link-arrow');

  dropDownLink.addEventListener('click', ()=> {
      dropDownMenu.classList.toggle('active');
      dropDownArrow.classList.toggle('active');
      dropDownLink.classList.toggle('active');
  });


//--------------------   filter   --------------------

let filterCosmetics = document.querySelector('.catalog-filter'),
    filterCosmeticsList = document.querySelector('.catalog-filter__list'),
    filterCosmeticsWrap = document.querySelector('.catalog-filter-wrap');

    if (isElementExist('.catalog-filter')) {
      filterCosmetics.addEventListener('click', ()=> {
        filterCosmeticsList.classList.toggle('active');
        filterCosmetics.classList.toggle('active');
        filterCosmeticsWrap.classList.toggle('active');
      })
    }

// --------------------   tab-momile

  let tab = function() {
    let tabNav = document.querySelectorAll('.tab-click'),
        tabContent = document.querySelectorAll('.tab'),
        vitamMicroelItem = document.querySelectorAll('.vitamins-microelements__item_vitamins'),
        tabName;
        
        tabNav.forEach( item => {
            item.addEventListener('click', selectTabNav)
        });

        function selectTabNav(){
            tabNav.forEach(item => {
                item.classList.remove('active');
            });
            this.classList.add('active');
            tabName = this.getAttribute('data-tab-name');
            selectTabContent(tabName);
        }

        function selectTabContent(tabName){
            tabContent.forEach(item => {
                item.classList.contains(tabName) ? item.classList.add('active') :
                 item.classList.remove('active');
            })
          } 
};
tab();

// --------------------   vitamins-microelements-tabs

let btnElements = document.querySelector('.vitamins-microelements__btn_elements'),
      btnVitamins = document.querySelector('.vitamins-microelements__btn_vitamins'),
      microelementsItem = document.querySelector('.vitamins-microelements__item_microelements'),
      vitaminsItem = document.querySelector('.vitamins-microelements__item_vitamins'),
      microelements = document.querySelector('.microelements'),
      vitamins = document.querySelector('.vitamins'),
      vitaminsItemMob = document.querySelector('.vitamins__item-mob'),
      microelementsItemMob = document.querySelector('.microelements__item-mob'),
      halfBg = document.querySelector('.vitamins-microelements__half');

      if (isElementExist('.vitamins__item-mob')) {
        vitaminsItemMob.addEventListener('click', ()=>{
          halfBg.classList.add('animate-right-half');
          halfBg.classList.remove('animate-left-half');
        });
      };
      
      if (isElementExist('.microelements__item-mob')) {
        microelementsItemMob.addEventListener('click', ()=>{
          halfBg.classList.add('animate-left-half');
          halfBg.classList.remove('animate-right-half');
        });
      };
      
      if (isElementExist('.vitamins-microelements__btn_vitamins')) {
        btnVitamins.addEventListener('click', ()=> {
          btnVitamins.classList.add('hide');
          btnElements.classList.remove('hide');
          microelementsItem.classList.remove('active');
          vitaminsItem.classList.add('active');
          microelements.classList.remove('active');
          vitamins.classList.add('active');
          halfBg.classList.add('animate-right-half');
          halfBg.classList.remove('animate-left-half');
        });
      };

      if (isElementExist('.vitamins-microelements__item_microelements')) {
        microelementsItem.addEventListener('click', ()=>{
          btnElements.classList.add('hide');
          btnVitamins.classList.remove('hide');
           halfBg.classList.add('animate-left-half');
           halfBg.classList.remove('animate-right-half');
        });
      };

      if (isElementExist('.vitamins-microelements__item_vitamins')) {
        vitaminsItem.addEventListener('click', ()=>{
          btnElements.classList.remove('hide');
          btnVitamins.classList.add('hide');
          halfBg.classList.add('animate-right-half');
          halfBg.classList.remove('animate-left-half');
        });
      };

      if (isElementExist('.vitamins-microelements__btn_elements')) {
        btnElements.addEventListener('click', ()=> {
          btnElements.classList.add('hide');
          btnVitamins.classList.remove('hide');
          vitaminsItem.classList.add('active');
          microelementsItem.classList.add('active');
          vitaminsItem.classList.remove('active');
          vitamins.classList.remove('active');
          microelements.classList.add('active');
          halfBg.classList.add('animate-left-half');
           halfBg.classList.remove('animate-right-half');
        });
      };

// //--------------------   slick   --------------------

// if (isElementExist('.block-carousel-slider_team')) {
//   $('.block-carousel-slider_team').slick({
//     infinite: true,
//     arrows: true,
//     prevArrow: "<button type='button' class='slick-prev block-carousel-slider-arrow slick-arrow'></button>",
//     nextArrow: "<button type='button' class='slick-next block-carousel-slider-arrow slick-arrow'></button>",
//     responsive: [
//       {
//         breakpoint: 4600,
//         settings: {
//           slidesToShow: 4,
//         }
//       }, 
//       {
//         breakpoint: 1200,
//         settings: {
//           slidesToShow: 1,
//           variableWidth: true,
//         }
//       }
//     ]
//   });
// };

// if (isElementExist('.block-carousel-slider_instagram')) {
//   $('.block-carousel-slider_instagram').slick({
//     infinite: true,
//     arrows: true,
//     prevArrow: "<button type='button' class='slick-prev block-carousel-slider-arrow slick-arrow'></button>",
//     nextArrow: "<button type='button' class='slick-next block-carousel-slider-arrow slick-arrow'></button>",
//     responsive: [
//       {
//         breakpoint: 4600,
//         settings: {
//           slidesToShow: 4,
//         }
//       }, 
//       {
//         breakpoint: 1200,
//         settings: {
//           slidesToShow: 1,
//           variableWidth: true,
//         }
//       }
//     ]
//   });
// };





// // -------------------- ФУНКЦИЯ, ЧТО ДОЛЖНА СРАБАТЫВАТЬ В СЛАЙДЕРЕ

// // function animateBannerSlide(){
// // let slide = document.querySelector('.banner__slide'),
// //     load = document.querySelector('.banner__load-line');
// //     slide.classList.add('animate');
// //     load.style.display = 'none';
// // // function loadLine() {
// // //   load.style.display = 'block';
// // // }
// // // setTimeout(loadLine, 2000);

// // }


// // animateBannerSlide();


  // dropDownLinkDesk.addEventListener('click', toggleDropDownMenu);

  // document.body.addEventListener('click', toggleDropDownMenu);

  // function toggleDropDownMenu (e) {
  //   if (e.target.classList.contains('nav__link_catalog')) {
  //     console.log(e.target);
  //     dropDownMenuDesk.classList.toggle('active');
  //     dropDownLinkDesk.classList.toggle('active');
  //   } else {
  //     dropDownMenuDesk.classList.remove('active');
  //   }
  // }


  


  

  

 







// // --------------------   forms

  // let buttonCheck = document.querySelector('.button_check-discount'),
  //     formDiscount = document.querySelector('.form-discount'),
  //     formCatalog = document.querySelector('.form-catalog');



  // function getValue(e){
  //       this.inputName = document.querySelector('.form__input_name'),
  //       this.inputEmail = document.querySelector('.form__input_email'),
  //       this.inputWrapperName = document.querySelector('.form__input-wrap_name'),
  //       this.inputWrapperEmail = document.querySelector('.form__input-wrap_email');
        
  //       let catalogBlock = document.querySelector('.catalog'),
  //           discountvlock = document.querySelector('.discount'),
  //           blocks = [catalogBlock, discountvlock];

  //       if (!this.inputName.value || !this.inputEmail.value) {
  //         this.inputWrapperName.classList.add('mistake');
  //         this.inputWrapperEmail.classList.add('mistake');
  //         blocks.forEach(e => {
  //           e.addEventListener('click', function() {
  //             inputWrapperEmail.classList.remove('mistake');
  //             inputWrapperName.classList.remove('mistake');
          
  //         })})
  //         return false;
  //       }
  //        else
  //        {
  //         this.inputWrapperName.classList.remove('mistake');
  //         this.inputWrapperEmail.classList.remove('mistake');
  //         return true;
  //       };
  // }

  // // this.buttonCheck.addEventListener('click', getValue)
  // // this.buttonCheck.addEventListener('click', ()=> {
  // //   console.log(this);
  // // })

  // // buttonCheck.addEventListener('click', ()=>{
    
  // // });


  
//   // function emptyInput(){
   
//   // }


// --------------------   modals

let modal = document.querySelectorAll('.modal'),
	modalSale = document.querySelector('.modal-sale'),
	modalCallBack = document.querySelector('.modal-recall'),
	modalEntry = document.querySelector('.modal-entrance'),
	modalThanksCallBack = document.querySelector('.modal-thanks_callback'),
	// modalThanksGreen = document.querySelector('.modal-thanks_green'),
	modalThanksCatalog = document.querySelector('.modal-thanks_catalog'),
	btnCallBack = document.querySelectorAll('.button_call-back'),
	btnCloseModal = document.querySelectorAll('.modal__close'),
	modalBackgroundClose = document.querySelectorAll('.modal__bg'),
	buttonEntry = document.querySelectorAll('.button_entry'),
	buttonCheckout = document.querySelector('.button_checkout');

// const openModalThanksCallBack = ()=> {

//   modalCallBack.classList.remove('active');
//   modalThanksCallBack.classList.add('active');
//   body.classList.add('overflow');
//   setTimeout(closeModal, 3000);
//   event.preventDefault();
// }

// buttonThanksPink.forEach(e => {
// e.addEventListener('click', openModalThanksBlue);
// })

// btnCallBack.forEach( e => {
//   e.addEventListener('click', openModalCallBack);
// } );

// const openModalThanksGreen = ()=> {
// modalThanksGreen.classList.add('active');
// body.classList.add('overflow');
// setTimeout(closeModal, 3000);
// }

const openModalThanks = (type = 'callBackModal')=> {
	
  if(type === 'catalogModal') {
	modalThanksCatalog.classList.add('active');
  } else {
	modalCallBack.classList.remove('active');
	modalThanksCallBack.classList.add('active');
  }
  body.classList.add('overflow');
  setTimeout(closeModal, 3000);
}

const openModalCallBack = () => {
  event.preventDefault();
  modalCallBack.classList.add('active');
  body.classList.add('overflow');
}

const openModalSale = () => {
	// event.preventDefault();
	modalSale.classList.add('active');
	body.classList.add('overflow');
  }

const closeModal = () => {
  modal.forEach(e => {
    e.classList.remove('active');
  })
  mobileMenu.classList.remove('active');
  hamburgerMenu.classList.remove('active');
  entryMob.classList.remove('active');
  body.classList.remove('overflow');
}

const openModalInfoUse = () => {
  modalInfoUse.classList.add('active');
  body.classList.add('overflow');
}

const openModalInfoInformation = () => {
  modalInfoInformation.classList.add('active');
  body.classList.add('overflow');
}

const opemModalSale = () => {
	modalSale.classList.add('active');
	body.classList.add('overflow');
}

const openModalEntry = () => {
  modalEntry.classList.add('active');
  body.classList.add('overflow');
}

//   // function toggleDropDownMenu (e) {
//   //   if (e.target.classList.contains('nav__link_catalog')) {
//   //     console.log(e.target);
//   //     dropDownMenuDesk.classList.toggle('active');
//   //     dropDownLinkDesk.classList.toggle('active');
//   //   } else {
//   //     dropDownMenuDesk.classList.remove('active');
//   //   }
//   // }



// --------------------   btns for modals

let btnGetCatalog = document.querySelector('.button-get-catalog'),
    buttonThanksCallBack = document.querySelectorAll('.button_thanks-call-back'),
    buttonCallBack = document.querySelectorAll('.button-callback'),
    buttonInfoUse = document.querySelector('.button-info-use'),
    buttonBasketSmall = document.querySelector('.basket__btn'),
    buttonInfoInformation = document.querySelector('.button-info-information'),
    modalInfoInformation = document.querySelector('.modal-info_information'),
    modalInfoUse = document.querySelector('.modal-info_use');

    // if (isElementExist('.basket__btn')) {
    //   buttonBasketSmall.addEventListener('click', toogleBasketSmall);
    // }

    if (isElementExist('.button-info-use')) {
      buttonInfoUse.addEventListener('click', openModalInfoUse);
	}
	
	
    if (isElementExist('.button-info-information')) {
      buttonInfoInformation.addEventListener('click', openModalInfoInformation);
    }

    // if (isElementExist('.button-get-catalog')) {
    //   btnGetCatalog.addEventListener('click', openModalThanksCatatalog);
    // }

    if (isElementExist('.button-callback')) {
      buttonCallBack.forEach(item => {
        item.addEventListener('click', openModalCallBack)
      })
    }

    // if (isElementExist('.button_thanks-call-back')) {
    //   buttonThanksCallBack.forEach(item => {
    //     item.addEventListener('click', openModalThanksCallBack)
    //   })
    // }

    buttonEntry.forEach( e => {
      e.addEventListener('click', openModalEntry);
    });

    btnCloseModal.forEach( e => {
      e.addEventListener('click', closeModal);
    } )
    
    modalBackgroundClose.forEach( e => {
      e.addEventListener('click', closeModal);
    } )

// --------------------   chenge card for payment-delivery   --------------------

let cardPayDel = document.querySelectorAll('.card-wrap');


if (isElementExist('.card-wrap')) {
  cardPayDel.forEach(item => {
    item.addEventListener('click', ()=> {
      cardPayDel.forEach(i => {
        i.classList.remove('active');
      })
      item.classList.toggle('active');
    })
  });
}

// --------------------   megamenu

let megamenuSideRight = document.querySelector('.megamenu__side_right'),
	megamenuSideLeft = document.querySelector('.megamenu__side_left');

	megamenuSideRight.addEventListener('mouseover', ()=> {
		megamenuSideLeft.classList.add('inactive');
	});

	megamenuSideRight.addEventListener('mouseout', ()=> {
		megamenuSideLeft.classList.remove('inactive');
	});

// // --------------------   header
  
  let header = document.querySelector('.header');

window.onscroll = function() {

  let scrolled = window.pageYOffset,
      bannerSectionOffset = '800';
  
  if ( scrolled > bannerSectionOffset ) {
      header.classList.add('header_sticky');
  } else {
      header.classList.remove('header_sticky');
  }
};

// // --------------------   animate
  
// // function animateFlowerAboutProduct() {  

// //   let block = document.querySelector('.about-product'),
// //       blockHeight = document.documentElement.clientHeight,
// //       blockOffset = block.offsetTop - blockHeight,
// //       circleWrap = document.querySelector('.about-product .circle-wrap'),
// //       backgroundSection = document.querySelector('.about-product .background-section'),
// //       blockCaption = document.querySelector('.about-product .main-caption'),
// //       blockText = document.querySelector('.about-product .main-text'),
// //       flower = document.querySelector('.about-product .circle');

    
// //       startFunc = true;
// //   function doSomeThing(callback) {
// //       flower.classList.add('animate-flower');
// //       circleWrap.classList.add('animate');
// //       backgroundSection.classList.add('animate');
// //       blockCaption.classList.add('animate');
// //       blockText.classList.add('animate');
// //       callback();
// //   }
// //   function stopDoSomeThing() {
// //       startFunc = false;
// //   }
// //   window.addEventListener('scroll', function () {

// //       let scrollPosition = window.scrollY;

// //       if (scrollPosition >= blockOffset && startFunc) {
// //           doSomeThing(stopDoSomeThing);
// //       }
// //   });
// // };

// // animateFlowerAboutProduct();


// // function animateFlowerDiscount() {  

// //   let block = document.querySelector('.discount'),
// //       blockHeight = document.documentElement.clientHeight,
// //       blockOffset = block.offsetTop - blockHeight,
// //       circleWrap = document.querySelector('.discount .circle-wrap'),
// //       backgroundSection = document.querySelector('.discount .background-section'),
// //       flower = document.querySelector('.discount .circle'),
// //       blockCaption = document.querySelector('.discount .main-caption'),
// //       blockForm = document.querySelector('.discount .form'),
// //       startFunc = true;

// //   function doSomeThing(callback) {
// //       flower.classList.add('animate-flower');
// //       circleWrap.classList.add('animate');
// //       backgroundSection.classList.add('animate');
// //       blockForm.classList.add('animate');
// //       blockCaption.classList.add('animate');
// //       callback();
// //   }
// //   function stopDoSomeThing() {
// //       startFunc = false;
// //   }
// //   window.addEventListener('scroll', function () {

// //       let scrollPosition = window.scrollY;

// //       if (scrollPosition >= blockOffset && startFunc) {
// //           doSomeThing(stopDoSomeThing);
// //       }
// //   });
// // };

// // animateFlowerDiscount();


// // function animateFlowerСatalog() {  

// //   let block = document.querySelector('.catalog'),
// //       blockHeight = document.documentElement.clientHeight,
// //       blockOffset = block.offsetTop - blockHeight,
// //       circleWrap = document.querySelector('.catalog .circle-wrap'),
// //       backgroundSection = document.querySelector('.catalog .background-section'),
// //       flower = document.querySelector('.catalog .circle'),
// //       blockCaption = document.querySelectorAll('.catalog .main-caption'),
// //       blockForm = document.querySelector('.catalog .form'),
// //       startFunc = true;

// //   function doSomeThing(callback) {
// //       flower.classList.add('animate-flower');
// //       circleWrap.classList.add('animate');
// //       backgroundSection.classList.add('animate');
// //       blockForm.classList.add('animate');
// //       blockCaption.forEach(item => {
// //         item.classList.add('animate');
// //       });
// //       callback();
// //   }
// //   function stopDoSomeThing() {
// //       startFunc = false;
// //   }
// //   window.addEventListener('scroll', function () {

// //       let scrollPosition = window.scrollY;

// //       if (scrollPosition >= blockOffset && startFunc) {
// //           doSomeThing(stopDoSomeThing);
// //       }
// //   });
// // };

// // animateFlowerСatalog();

// // function animateFlowerQuestions() {  

// //   let block = document.querySelector('.questions'),
// //       blockHeight = document.documentElement.clientHeight,
// //       blockOffset = block.offsetTop - blockHeight,
// //       flower = document.querySelector('.questions .circle'),
// //       circleWrap = document.querySelector('.questions .circle-wrap'),
// //       backgroundSection = document.querySelector('.questions .background-section'),
// //       blockCaption = document.querySelector('.questions .main-caption'),
// //       blockForm = document.querySelector('.questions .form'),
// //       startFunc = true;
// //   function doSomeThing(callback) {
// //       flower.classList.add('animate-flower');
// //       circleWrap.classList.add('animate');
// //       backgroundSection.classList.add('animate');
// //       blockForm.classList.add('animate');
// //       blockCaption.classList.add('animate');
// //       callback();
// //   }
// //   function stopDoSomeThing() {
// //       startFunc = false;
// //   }
// //   window.addEventListener('scroll', function () {

// //       let scrollPosition = window.scrollY;

// //       if (scrollPosition >= blockOffset && startFunc) {
// //           doSomeThing(stopDoSomeThing);
// //       }
// //   });
// // };

// // animateFlowerQuestions();


// // function animateBlockAdvantages() {  

// //   let block = document.querySelector('.advantages'),
// //       blockHeight = document.documentElement.clientHeight,
// //       blockOffset = block.offsetTop - blockHeight,
// //       circle = document.querySelectorAll('.advantages-carousel__slide'),
// //       blockTitle = document.querySelectorAll('.advantages__title'),
// //       blockText = document.querySelectorAll('.advantages__text'),
// //       startFunc = true;
// //   function doSomeThing(callback) {
// //       circle.forEach(item => {
// //         item.classList.add('animate');
// //       });
// //       block.classList.add('animate'); 
// //       callback();
// //   }
// //   function stopDoSomeThing() {
// //       startFunc = false;
// //   }
// //   window.addEventListener('scroll', function () {

// //       let scrollPosition = window.scrollY;

// //       if (scrollPosition >= blockOffset && startFunc) {
// //           doSomeThing(stopDoSomeThing);
// //       }
// //   });
// // };

// // animateBlockAdvantages();

// // function animateBlockProduction() {  

// //   let block = document.querySelector('.production'),
// //       blockHeight = document.documentElement.clientHeight,
// //       blockOffset = block.offsetTop - blockHeight,
// //       circle = document.querySelector('.production__icons-wrap'),
// //       arrow = document.querySelectorAll('.production__icon-arrow'),
// //       backgroundSection = document.querySelector('.production .background-section'),
// //       productionWrap = document.querySelector('.production-inner-wrap'),
// //       video = document.querySelector('.video'),
// //       startFunc = true;
// //   function doSomeThing(callback) {
// //       //circle.classList.add('animate');
// //       block.classList.add('animate');
// //       //backgroundSection.classList.add('animate');
// //       //video.classList.add('animate');
// //       callback();
// //   }
// //   function stopDoSomeThing() {
// //       startFunc = false;
// //   }
// //   window.addEventListener('scroll', function () {

// //       let scrollPosition = window.scrollY;

// //       if (scrollPosition >= blockOffset && startFunc) {
// //           doSomeThing(stopDoSomeThing);
// //       }
// //   });
// // };

// // animateBlockProduction();

// // function animateBlockQuote() {  

// //   let block = document.querySelector('.block-quote'),
// //       blockHeight = document.documentElement.clientHeight,
// //       blockOffset = block.offsetTop - blockHeight,
// //       startFunc = true;
// //   function doSomeThing(callback) {
// //       block.classList.add('animate');
// //       callback();
// //   }
// //   function stopDoSomeThing() {
// //       startFunc = false;
// //   }
// //   window.addEventListener('scroll', function () {

// //       let scrollPosition = window.scrollY;

// //       if (scrollPosition >= blockOffset && startFunc) {
// //           doSomeThing(stopDoSomeThing);
// //       }
// //   });
// // };

// // animateBlockQuote();


// // function animateBlockVitamMicroel() {  

// //   let block = document.querySelector('.vitamins-microelements'),
// //       blockHeight = document.documentElement.clientHeight,
// //       blockOffset = block.offsetTop - blockHeight,
// //       startFunc = true;
// //   function doSomeThing(callback) {
// //       block.classList.add('animate');
// //       callback();
// //   }
// //   function stopDoSomeThing() {
// //       startFunc = false;
// //   }
// //   window.addEventListener('scroll', function () {

// //       let scrollPosition = window.scrollY;

// //       if (scrollPosition >= blockOffset && startFunc) {
// //           doSomeThing(stopDoSomeThing);
// //       }
// //   });
// // };

// // animateBlockVitamMicroel();

// // function animateBlockTeam() {  

// //   let block = document.querySelector('.team'),
// //       blockHeight = document.documentElement.clientHeight,
// //       blockOffset = block.offsetTop - blockHeight,
// //       startFunc = true;
// //   function doSomeThing(callback) {
// //       block.classList.add('animate');
// //       callback();
// //   }
// //   function stopDoSomeThing() {
// //       startFunc = false;
// //   }
// //   window.addEventListener('scroll', function () {

// //       let scrollPosition = window.scrollY;

// //       if (scrollPosition >= blockOffset && startFunc) {
// //           doSomeThing(stopDoSomeThing);
// //       }
// //   });
// // };

// // animateBlockTeam();

// // function animateBlockInstagram() {  

// //   let block = document.querySelector('.instagram'),
// //       blockHeight = document.documentElement.clientHeight,
// //       blockOffset = block.offsetTop - blockHeight,
// //       startFunc = true;
// //   function doSomeThing(callback) {
// //       block.classList.add('animate');
// //       callback();
// //   }
// //   function stopDoSomeThing() {
// //       startFunc = false;
// //   }
// //   window.addEventListener('scroll', function () {

// //       let scrollPosition = window.scrollY;

// //       if (scrollPosition >= blockOffset && startFunc) {
// //           doSomeThing(stopDoSomeThing);
// //       }
// //   });
// // };

// // animateBlockInstagram();


// // function animateBlockAboutSpirulin() {  

// //   let block = document.querySelector('.about-spirulin'),
// //       blockHeight = document.documentElement.clientHeight,
// //       blockOffset = block.offsetTop - blockHeight,
// //       startFunc = true;
// //   function doSomeThing(callback) {
// //       block.classList.add('animate');
// //       callback();
// //   }
// //   function stopDoSomeThing() {
// //       startFunc = false;
// //   }
// //   window.addEventListener('scroll', function () {

// //       let scrollPosition = window.scrollY;

// //       if (scrollPosition >= blockOffset && startFunc) {
// //           doSomeThing(stopDoSomeThing);
// //       }
// //   });
// // };

// // animateBlockAboutSpirulin();




// //--------------------  banner-load-animation   --------------------

// // function bannerLoad() {

// //   line = document.querySelectorAll('.banner__load-line');

// //   line.forEach(item => {
// //     item.classList.remove('animate');
// //     item.classList.add('animate');
// //   })
// //   console.log(line);
// // }










