import {isElementExist} from "./helpers";

export default (function () {
	$(document).ready(function () {
		if (isElementExist('.block-carousel-slider_team')) {
			$('.block-carousel-slider_team').not('.slick-initialized').slick({
				infinite: true,
				arrows: true,
				variableWidth: true,
				// slidesToShow: 4,
				prevArrow: "<button type='button' class='slick-prev block-carousel-slider-arrow slick-arrow'></button>",
				nextArrow: "<button type='button' class='slick-next block-carousel-slider-arrow slick-arrow'></button>",
				responsive: [
					{
						breakpoint: 1200,
						settings: {
							// slidesToShow: 3.5,
							variableWidth: true,
						}
					}
				]
			});
		}

		if (isElementExist('.block-carousel-slider_instagram')) {
			$('.block-carousel-slider_instagram').slick({
				infinite: true,
				arrows: true,
				variableWidth: true,
				// slidesToShow: 4,
				prevArrow: "<button type='button' class='slick-prev block-carousel-slider-arrow slick-arrow'></button>",
				nextArrow: "<button type='button' class='slick-next block-carousel-slider-arrow slick-arrow'></button>",
				responsive: [
					{
						breakpoint: 1200,
						settings: {
							// slidesToShow: 3.5,
							variableWidth: true,
						}
					}
				]
			});
		}
		
		if (isElementExist('.banner')) {
			const banner = $('.banner');
			const bannerSlides = document.querySelectorAll('.banner .banner__slide')
			
			banner.not('.slick-initialized').slick({
				infinite: true,
				fade: true,
				arrows: true,
				speed: 1600,
			});
			
			let next = 0;
			let prev = 0;
			
			// banner.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
			// 	prev = currentSlide;
			// 	next = nextSlide;
				
				// const bannerBgCur = bannerSlides[currentSlide].querySelector('.banner__background');
				// const circleCur = bannerSlides[currentSlide].querySelector('.circle');
				// const mainCaptionCur = bannerSlides[currentSlide].querySelector('.main-caption');
				// const mainTextCur = bannerSlides[currentSlide].querySelector('.main-text');
				// const buttonCur = bannerSlides[currentSlide].querySelector('.button_call-back');
				// const buttonMoreCur = bannerSlides[currentSlide].querySelector('.button-more');
				
				// bannerBgCur.classList.add('slideOutRight');
				// circleCur.classList.add('zoomOut');
				// mainCaptionCur.classList.add('fadeOutDown');
				// mainTextCur.classList.add('fadeOutDown');
				// buttonCur.classList.add('fadeOutDown');
				// buttonMoreCur.classList.add('fadeOutDown');
			// });
			
			banner.on('afterChange', function (event, slick, currentSlide) {
			
				
				// const bannerBgPrev = bannerSlides[prev].querySelector('.banner__background');
				// const circlePrev = bannerSlides[prev].querySelector('.circle');
				// const mainCaptionPrev = bannerSlides[prev].querySelector('.main-caption');
				// const mainTextPrev = bannerSlides[prev].querySelector('.main-text');
				// const buttonPrev = bannerSlides[prev].querySelector('.button_call-back');
				// const buttonMorePrev = bannerSlides[prev].querySelector('.button-more');
				
				// const bannerBgCur = bannerSlides[currentSlide].querySelector('.banner__background');
				// const circleCur = bannerSlides[currentSlide].querySelector('.circle');
				// const mainCaptionCur = bannerSlides[currentSlide].querySelector('.main-caption');
				// const mainTextCur = bannerSlides[currentSlide].querySelector('.main-text');
				// const buttonCur = bannerSlides[currentSlide].querySelector('.button_call-back');
				// const buttonMoreCur = bannerSlides[currentSlide].querySelector('.button-more');
				
				// // remove classes
				// bannerBgPrev.classList.remove('slideOutRight', 'slideInRight');
				// circlePrev.classList.remove('zoomOut', 'zoomIn');
				// mainCaptionPrev.classList.remove('fadeOutDown', 'fadeInUp');
				// mainTextPrev.classList.remove('fadeOutDown', 'fadeInUp');
				// buttonPrev.classList.remove('fadeOutDown', 'fadeInUp');
				// buttonMorePrev.classList.remove('fadeOutDown', 'fadeInUp');
				
				// // adding classes
				// bannerBgCur.classList.add('slideInRight');
				// circleCur.classList.add('zoomIn');
				// mainCaptionCur.classList.add('fadeInUp');
				// mainTextCur.classList.add('fadeInUp');
				// buttonCur.classList.add('fadeInUp');
				// buttonMoreCur.classList.add('fadeInUp');
			});
		}
		
		if (isElementExist('.current-products-carousel')) {
			$('.current-products-carousel').not('.slick-initialized').slick({
				infinite: true,
				slidesToShow: 1,
				variableWidth: true,
				prevArrow: "<button type='button' class='slick-prev carousel-arrow slick-arrow'></button>",
				nextArrow: "<button type='button' class='slick-next carousel-arrow slick-arrow'></button>",
				responsive: [
					{
						breakpoint: 1200,
						settings: {
						  arrows: false,
						}
					  },
				]
			});
		}
		
		if (isElementExist('.recipes-last-carousel')) {
			$('.recipes-last-carousel').not('.slick-initialized').slick({
				infinite: true,
				slidesToShow: 1,
				variableWidth: true,
				prevArrow: "<button type='button' class='slick-prev carousel-arrow slick-arrow'></button>",
				nextArrow: "<button type='button' class='slick-next carousel-arrow slick-arrow'></button>",
				responsive: [
					{
						breakpoint: 1200,
						settings: {
						  arrows: false,
						}
					}
				]
			});
		}
		
		if (isElementExist('.product-card-section-slider-nav')) {
			$('.product-card-section-slider-nav').not('.slick-initialized').slick({
				infinite: true,
				slidesToShow: 1,
				// variableWidth: true,
				arrows: false,
				slidesToShow: 4,
				slidesToScroll: 1,
				asNavFor: '.product-card-section-slider',
				focusOnSelect: true,
				vertical: true,
				verticalSwiping: true,
			});
		}
		
		if (isElementExist('.product-card-section-slider')) {
			$('.product-card-section-slider').not('.slick-initialized').slick({
				infinite: true,
				slidesToShow: 1,
				prevArrow: "<button type='button' class='slick-prev carousel-arrow slick-arrow'></button>",
				nextArrow: "<button type='button' class='slick-next carousel-arrow slick-arrow'></button>",
				asNavFor: '.product-card-section-slider-nav',
			});
		}
		
		if (isElementExist('.special-offer-slider')) {
			$('.special-offer-slider').not('.slick-initialized').slick({
				infinite: true,
				slidesToShow: 1,
				fade: true,
				prevArrow: "<button type='button' class='slick-prev carousel-arrow slick-arrow'></button>",
				nextArrow: "<button type='button' class='slick-next carousel-arrow slick-arrow'></button>",
			});
		}
		
		if (isElementExist('.advantages-carousel')) {
			const advCarousel = $('.advantages-carousel');
			if (window.innerWidth <= 768 && !advCarousel.hasClass('slick-initialized')) {
				$('.advantages-carousel').not('.slick-initialized').slick({
					infinite: true,
					variableWidth: true,
					arrows: false,
					slidesToShow: 1,
					infinite: true,
				});
			} else {
				if(advCarousel.hasClass('slick-initialized')) {
					advCarousel.slick('unslick')
				}
			}
			$(window).resize(function () {
				if (window.innerWidth <= 1199 && !advCarousel.hasClass('slick-initialized')) {
					advCarousel.not('.slick-initialized').slick({
						infinite: true,
						variableWidth: true,
						arrows: false,
						slidesToShow: 1,
						infinite: true,
					});
				} else {
					if (window.innerWidth > 1199 && advCarousel.hasClass('slick-initialized')) {
						advCarousel.slick('unslick')
					}
				}
			});
		}
		
		if (isElementExist('.product-card-slider__main') && isElementExist('.product-card-slider__nav')) {
			$('.product-card-slider__main').not('.slick-initialized').slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: true,
				fade: true,
				asNavFor: '.product-card-slider__nav'
			});
			$('.product-card-slider__nav').not('.slick-initialized').slick({
				slidesToShow: 4,
				slidesToScroll: 1,
				asNavFor: '.product-card-slider__main',
				focusOnSelect: true,
				vertical: true,
				verticalSwiping: true,
				responsive: [
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 3,
							vertical: false,
							verticalSwiping: false,
						}
					}
				]
			});
		}
		
		// if (isElementExist('.recipes-last-carousel')) {
		// 	$('.recipes-last-carousel').not('.slick-initialized').slick({
		// 		infinite: true,
		// 		slidesToShow: 1,
		// 		variableWidth: true,
		// 		prevArrow: "<button type='button' class='slick-prev carousel-arrow slick-arrow'></button>",
		// 		nextArrow: "<button type='button' class='slick-next carousel-arrow slick-arrow'></button>",
		// 	});
		// }
		
		if (isElementExist('.product-card-section-slider-nav')) {
			$('.product-card-section-slider-nav').not('.slick-initialized').slick({
				infinite: true,
				slidesToShow: 1,
				// variableWidth: true,
				arrows: false,
				slidesToShow: 4,
				slidesToScroll: 1,
				asNavFor: '.product-card-section-slider',
				focusOnSelect: true,
				vertical: true,
				verticalSwiping: true,
			});
		}
		
		if (isElementExist('.product-card-section-slider')) {
			$('.product-card-section-slider').not('.slick-initialized').slick({
				infinite: true,
				slidesToShow: 1,
				prevArrow: "<button type='button' class='slick-prev carousel-arrow slick-arrow'></button>",
				nextArrow: "<button type='button' class='slick-next carousel-arrow slick-arrow'></button>",
				asNavFor: '.product-card-section-slider-nav',
			});
		}
		
		if (isElementExist('.special-offer-slider')) {
			$('.special-offer-slider').not('.slick-initialized').slick({
				infinite: true,
				slidesToShow: 1,
				fade: true,
				prevArrow: "<button type='button' class='slick-prev carousel-arrow slick-arrow'></button>",
				nextArrow: "<button type='button' class='slick-next carousel-arrow slick-arrow'></button>",
			});
		}
	});
})();