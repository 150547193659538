export function isElementExist(elem) {
	return $(elem) && $(elem).length > 0;
}

export function animateCSS(element, animationName, callback) {
	let node = typeof element === 'string' ? document.querySelector(element) : element;
	if(node) {
		node.classList.add('animated', animationName)
		
		function handleAnimationEnd() {
			node.classList.remove('animated', animationName)
			node.removeEventListener('animationend', handleAnimationEnd)
			
			if (typeof callback === 'function') callback()
		}
		
		node.addEventListener('animationend', handleAnimationEnd)
	}
}