export function validateEmail(email) {
	let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

export function validateName(name) {
	let re = /^[\wа-яА-Я ]+$/;
	return re.test(String(name));
}

export function validatePhone(phone) {
	let re = /^[\+\d\(\)]+$/;
	return re.test(String(phone));
}

export function isRecallFormFn(formEl) {
	return formEl.classList.contains('modal-recall__form')
}


// ***************************
// Validate simple form function
// ***************************
export function validateSimpleForm(formEl) {
	
	// UI errors Fn()
	const errorsUI = (selector, isValid) => {
		let field = selector;
		let display = isValid ? 'none' : 'block';
		let toggleMethod = isValid ? 'remove' : 'add';
		let errorBlock = field.parentElement.querySelector('.form__mistake');
		field.parentElement.classList[toggleMethod]('mistake');
		errorBlock ? errorBlock.style.display = display : false
	};
	
	// Form element
	const form = formEl;
	const isRecallForm = isRecallFormFn.call(this, formEl);
	
	// Define default validations
	let nameIsValid;
	let phoneIsValid;
	let emailIsValid;
	
	// Name
	const name = form.querySelector('[name="name"]');
	const nameValue = name.value;
	nameIsValid = nameValue && nameValue.length > 3 ? validateName(nameValue) : false;
	errorsUI(name, nameIsValid);
	
	// Phone
	const phone = form.querySelector('[name="phone"]');
	const phoneValue = phone.value;
	phoneIsValid = phoneValue.length > 3 ? validatePhone(phoneValue) : false;
	errorsUI(phone, phoneIsValid);
	
	// Check if it not a recall form
	if(!isRecallForm) {
		// Email
		const email = form.querySelector('[name="email"]');
		const emailValue = email.value;
		emailIsValid = emailValue.length > 0 ? validateEmail(emailValue) : false;
		errorsUI(email, emailIsValid);
	}
	
	// Diff. types of validation fields that are require
	const isFormValid = () => {
		let fieldsToValidate = nameIsValid && phoneIsValid;
		if(!isRecallForm) {
			fieldsToValidate = nameIsValid && phoneIsValid && emailIsValid;
		}
		return fieldsToValidate
	};
	
	return isFormValid()
}